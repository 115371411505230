<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog" :persistent="loadingForm">
    <v-card>
      <v-card-title primary-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close" :disabled="loadingForm">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form :ref="formRef">
          <v-autocomplete
            item-text="name"
            item-value="id"
            :items="plantillas"
            v-model="plantillaSeleccionada"
            label="Plantillas"
            :loading="loadingPlantillas"
            :rules="[(v) => !!v || 'Debe seleccionar una plantilla.']"
          ></v-autocomplete>

          <v-text-field
            v-model="documento"
            :rules="[
              (v) => !!v || 'El campo nombre del documento es requerido.',
            ]"
            name="Documento"
            label="Nuevo documento emision"
            placeholder="Escriba nombre del documento"
            id="documento"
          >
          </v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit" :loading="loadingForm">
          {{ labels.submit }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import FormMixin from "@/Mixins/SetFormMixin.js";

export default {
  name: "DocsEmisionModal",
  mixins: [FormMixin],
  data() {
    return {
      defaultTitle: "Formulario",
      documento: "",
      plantillaSeleccionada: null,
      plantillas: [],
      loadingPlantillas: false,
      loadingForm: false,
    };
  },
  methods: {
    ...mapActions("NewProduct", [
      "CrearDocumentEmision",
      "UpdateDocumentoEmision",
      "getPlantillasJsReport",
    ]),

    setup() {
      this.plantillas = [];
      this.loadingPlantillas = true;
      this.getPlantillasJsReport()
        .then((response) => {
          this.plantillas = response;
        })
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loadingPlantillas = false;
        });
    },

    setter() {
      this.documento = this.formData.documento_descripcion;
      this.plantillaSeleccionada = this.formData.plantillas.id;
    },

    send() {
      this.loadingForm = true;
      const plantillaSelected = this.plantillas.find(
        (e) => e.id === this.plantillaSeleccionada
      );
      const formData = {
        documento_descripcion: this.documento,
        nota: "",
        plantillas: [
          {
            plantilla: "",
            plantilla_url: "",
            plantilla_descripcion: "",
            ...(plantillaSelected ?? { id: this.plantillaSeleccionada }),
          },
        ],
      };
      let METHOD = this.CrearDocumentEmision;
      if (this.formData) {
        METHOD = this.UpdateDocumentoEmision;
        formData.id = this.formData._id;
      }
      METHOD(formData)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.$emit("submit");
        })
        .catch((error) => {
          this.snackbar(
            error.result?.lista_mensaje?.[0]?.mensaje || error.info
          );
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
  },
};
</script>
