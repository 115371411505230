<template>
  <v-container>
    <v-card>
      <v-data-table :search="search" v-bind="bind.table">
        <template #top>
          <div class="d-flex pa-3">
            <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
            <v-divider v-bind="bind.divider"></v-divider>
            <v-spacer></v-spacer>
            <v-btn v-bind="bind.refresh" v-on="on.refresh">
              <v-icon>{{ icons.refresh }}</v-icon>
            </v-btn>
            <v-btn v-bind="bind.new" v-on="on.new">{{ labels.new }}</v-btn>
          </div>
        </template>

        <template #item.actions="{ item }">
          <v-btn v-bind="bind.actions" v-on="on.edit(item)">
            <v-icon>{{ icons.edit }}</v-icon>
          </v-btn>
          <v-btn
            v-bind="bind.actions"
            v-on="on.delete(item)"
            color="error"
            :disabled="item.eliminado"
          >
            <v-icon>{{ icons.delete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <Modal v-model="dialog" v-bind="bind.form" v-on="on.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>
<script>
import Modal from "@/components/Modal/ModalProduct/Modal-DocumentosEmision";
import { mapActions } from "vuex";

import PageMixin from "@/Mixins/SetPageMixin.js";
export default {
  name: "DocumentosEmisionPage",
  mixins: [PageMixin],
  components: {
    Modal,
  },
  data: () => ({
    headers: [
      {
        text: "Documento",
        value: "documento_descripcion",
        align: "left",
      },
      { text: "Plantilla", value: "plantillas.name", align: "left" },
      {
        text: "Plantilla shortId",
        value: "plantillas.shortid",
        align: "left",
      },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    documentos: [],
  }),
  created() {
    this.setup();
  },
  methods: {
    ...mapActions("NewProduct", ["getDocEmision", "deleteDocumentoEmision"]),

    setup() {
      this.getDocumentos();
    },

    getDocumentos() {
      this.loading = true;
      this.getDocEmision()
        .then((response) => {
          this.documentos = response;
        })
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveItem() {
      this.closeForm();
      this.setup();
    },
    deleteItem(item) {
      this.confirm({
        title: "Eliminar documento",
        description: "¿Desea eliminar este documento de emisión?",
      }).then((answer) => {
        if (answer) {
          this.deleteDocumentoEmision(item._id)
            .then((response) => {
              this.snackbar(response.info, "success");
              this.setup();
            })
            .catch((error) => {
              this.snackbar(error.info);
            });
        }
      });
    },
  },

  computed: {
    items() {
      return this.documentos;
    },
  },
};
</script>
